import React, { useState } from "react";
import { Col, Layout, Row } from "antd";

import Sidebar from "../Sidebar/index";
import UserSidebar from "../UserSidebar/index";

import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import MobileFooter from "../../components/Mobile/Footer";

//import Container_PAPaymentInfo from "../PayAid/Payments/PAPaymentInfo";

import { Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";


const { Content, Footer } = Layout;

const PayAidMainApp = () => {

  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  let role = '';
  role = localStorage.getItem("role") && localStorage.getItem("role").toString();


  let layoutclass = '';
  let layoutcontainerclass = 'PA-Payment-container-wrapper';
  let mobileflag = 0;
  if (role !== 'Org_Admin') {
    mobileflag = 1;
    layoutclass = " user-layout";
  }

  const [collapsed, setCollapsed] = useState(false);


  return (
    <Layout className={layoutclass+" gx-app-layout" } style={{ minHeight: '100vh' }}>
      <Layout  className="site-layout">
        <Header className="PA-Payment-header-wrapper" >
        
          <Row>
            <Col span={16}>
          
                <img className="payaid-logo" alt="PayAid Logo" src={require("assets/images/payaid.png")} />
            </Col>
          </Row>
        </Header>
        {/* {getNavStyles(navStyle)} */}
        <Content className={` gx-layout-content ${getContainerClass(navStyle)} `}>
          <NotificationContainer />
          <div className={layoutcontainerclass}>
          <App match={match} />
          </div>
          <Footer style={{position:'fixed', bottom:'0px', width:'100%', textAlign: 'center' }}>
            <div className="gx-layout-footer-content">
              <span className='layoutFooter'>{footerText}</span>
              {mobileflag === 1 ? <MobileFooter /> : ''}

            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default PayAidMainApp;

