// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SWITCH_HEADING = 'SWITCH_HEADING'

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_FINALLYID_SET = 'USER_FINALLYID_SET'

//Assessment Const
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const ASSESMENT_RESULT = 'ASSESMENT_RESULT';
export const ASSESMENT_HISTORY = 'ASSESMENT_RESULT';
export const ASSESMENT_STATUS = 'ASSESMENT_STATUS';
export const ASSESMENT_FLAG = 'ASSESMENT_FLAG';

//Organization Const
export const FETCH_ALL_EMPLOYEES = 'FETCH_ALL_EMPLOYEES';
export const FETCH_ORGANIZATION_PROFILE = 'FETCH_ORGANIZATION_PROFILE';

//Client
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';
export const FETCH_ALL_LOANS = 'FETCH_ALL_LOANS';
export const FETCH_ALL_PAYMENTS = 'FETCH_ALL_PAYMENTS';
export const FETCH_UPLOAD_LOANS = 'FETCH_UPLOAD_LOANS';
export const FETCH_UPLOAD_CUSTOMERS = 'FETCH_UPLOAD_CUSTOMERS';
export const FETCH_SYNC_PENDING_RPCUSTOMERS_COUNT = 'FETCH_SYNC_PENDING_RPCUSTOMERS_COUNT';
export const FETCH_SYNC_PENDING_RPQRCODES_COUNT = 'FETCH_SYNC_PENDING_RPQRCODES_COUNT';
export const FETCH_SYNC_RPCUSTOMERS = 'FETCH_SYNC_RPCUSTOMERS';
export const FETCH_SYNC_RPQRCODES = 'FETCH_SYNC_RPQRCODES';

//RazorPay Const
export const FETCH_RP_ALL_CUSTOMERS = 'FETCH_RP_ALL_CUSTOMERS';
export const FETCH_RP_ALL_QRCODES = 'FETCH_RP_ALL_QRCODES';
export const FETCH_RP_GET_QRCODE_BY_LOANID = 'FETCH_RP_GET_QRCODE_BY_LOANID';
export const FETCH_RP_GET_QRCODE_BY_QRCODEID = 'FETCH_RP_GET_QRCODE_BY_QRCODEID';

//Dashboard Const
export const DASHBOARD_TODAY = 'DASHBOARD_TODAY';
export const DASHBOARD_7DAYS = 'DASHBOARD_7DAYS';
export const DASHBOARD_30DAYS = 'DASHBOARD_30DAYS';

export const ORGANIZATIONS_SUMMARY = 'ORGANIZATIONS_SUMMARY';