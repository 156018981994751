import { ORGANIZATIONS_SUMMARY } from "../../constants/ActionTypes";
const INIT_STATE = {
    organizationsSummaryData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ORGANIZATIONS_SUMMARY: {
            return {
                ...state,
                organizationsSummaryData: action.payload,
            };
        }

        default:
            return state;
    }
}