import {FETCH_SYNC_PENDING_RPCUSTOMERS_COUNT, FETCH_SYNC_RPQRCODES,FETCH_SYNC_PENDING_RPQRCODES_COUNT,FETCH_SYNC_RPCUSTOMERS} from "constants/ActionTypes";
const INIT_STATE = {
  syncPendingRPCustomersCount:{},
  syncPendingRPQRCodesCount:{},
  syncRPCustomers:[],
  syncRPQRCodes:[]
  };

  export default (state = INIT_STATE, action) => {

    switch (action.type) {
 
      case FETCH_SYNC_PENDING_RPCUSTOMERS_COUNT: {
        return {
          ...state,
          syncPendingRPCustomersCount: action.payload,
        };
      }
      case FETCH_SYNC_PENDING_RPQRCODES_COUNT: {
        return {
          ...state,
          syncPendingRPQRCodesCount: action.payload,
        };
      }
      case FETCH_SYNC_RPCUSTOMERS: {
        return {
          ...state,
          syncRPCustomers: action.payload,
        };
      }
      case FETCH_SYNC_RPQRCODES: {
        return {
          ...state,
          syncRPQRCodes: action.payload,
        };
      }

     

      default:
        return state;
    }
  }