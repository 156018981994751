import {DASHBOARD_TODAY,DASHBOARD_7DAYS,DASHBOARD_30DAYS} from "../../constants/ActionTypes";
const INIT_STATE = {
    assesmentStatusCardtodayData:{},
    assesmentStatusCard7daysdata:{},
    assesmentStatusCard30daysdata:{}
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
 
      case DASHBOARD_TODAY: {
        return {
          ...state,
          assesmentStatusCardtodayData: action.payload,
        };
      }

      case DASHBOARD_7DAYS: {
        return {
          ...state,
          assesmentStatusCard7daysdata: action.payload
        }
      }

      case DASHBOARD_30DAYS: {
        return {
          ...state,
          assesmentStatusCard30daysdata: action.payload
        }
      }

      default:
        return state;
    }
  }