import {FETCH_ALL_LOANS} from "constants/ActionTypes";
const INIT_STATE = {
  allLoans:[]
  };

  export default (state = INIT_STATE, action) => {

    switch (action.type) {
 
      case FETCH_ALL_LOANS: {
        return {
          ...state,
          allLoans: action.payload,
        };
      }

     

      default:
        return state;
    }
  }