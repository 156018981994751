import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Assesment from './Assessment';
import Dashboard from './Dashboard';
import { connectRouter } from 'connected-react-router'
import Organization from "./Organization";
import User from "./User";
import Admin from "./Admin";
import RPayCustomers from "./RPayCustomers";
import RPayQRCodesReducers from "./Client/RPayQRCodesReducers";
import Customers from "./Client/Customers";
import Loans from "./Client/Loans";
import UploadCsv from "./Client/UploadCsv";
import SyncReducers from "./Client/SyncReducers";
import Payments from "./Client/Payments";

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  assesment: Assesment,
  organization: Organization,
  dashboard: Dashboard,
  user: User,
  admin: Admin,
  rpaycustomers: RPayCustomers,
  rpayQRCodesReducers: RPayQRCodesReducers,
  customers: Customers,
  loans: Loans,
  uploadcsv: UploadCsv,
  sync: SyncReducers,
  reduind_payments:Payments
});
