import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeading } from "../../appRedux/actions/Setting";
import { Link, useHistory } from "react-router-dom";
import { Avatar } from "antd";

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const changeHeading = (heading, link) => {
    dispatch(setHeading(heading));
    history.push(link)
  }

  const { authUser } = useSelector(({ auth }) => auth);
  const { first_Name, last_Name } = authUser

  return (

    <div className="gx-flex-row gx-align-items-center  gx-avatar-row">
      <Link to="/userprofile" onClick={(e) => { e.preventDefault(); changeHeading("Profile", "/userprofile") }}>
        <Avatar src={require("assets/images/pentagon.png")}
          className="myavatar gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">{first_Name + ' ' + last_Name}</span>
      </Link>

    </div>

  )
};

export default UserProfile;
