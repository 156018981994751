import axios from 'axios';
import config from '../config/index'

let baseURL=config.baseApi;
let baseClientURL=config.baseApiClient;
export default axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const axiosClient =()=>{ 
  return axios.create({
  baseURL:baseClientURL,
  headers: {
    'Content-Type': 'application/json',
  }
})};
