import React from "react";
import { Button, Form, Input } from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";

const FormItem = Form.Item;


const ForgotPasswordPage = () => {

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = values => {
        console.log("Received values of form", values)
    };

    return (
        <div className="allBox forgotPage">
            <div className="forgotbox">
                <div className="gx-login-content">

                    {/* <div className="gx-login-header">
                        <img src={("images/logo-white.png")} alt="PWA" title="PWA" />
                    </div> */}
                    <div className="gx-mb-4">
                        <h2>Forgot Your Password ?</h2>
                        <p><IntlMessages id="app.userAuth.forgot" /></p>
                    </div>

                    <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="gx-signin-form gx-form-row0">
                        <FormItem name="email" rules={[{ required: true, message: 'Please input your E-mail!' }]}>
                            <Input className='gx-input-lineheight' type="email" placeholder="E-Mail Address" />
                        </FormItem>

                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                <IntlMessages id="app.userAuth.send" />
                            </Button>
                        </FormItem>
                        <Form.Item>
                            <span><IntlMessages id="app.userAuth.singInMess"/></span> <Link to="/signin"><IntlMessages
                            id="app.userAuth.signIn"/></Link>
                        </Form.Item>
                    </Form>

                </div>
            </div>
        </div>
        
    );
}

export default ForgotPasswordPage
