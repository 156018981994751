import React, {useState} from "react";
import {Divider, Layout, Popover, Tooltip} from "antd";
import {BellOutlined, SettingOutlined} from "@ant-design/icons";
import {Link,useHistory} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import SignOut from '../SignOut';
import Headertitle from 'components/Header';
import { setHeading } from "../../appRedux/actions/Setting";
import IntlMessages from "../../util/IntlMessages";


import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";


const {Header} = Layout;

const Topbar = () => {

  const history = useHistory();
const dispatch = useDispatch();
const changeHeading = (heading, link) => {

  dispatch(setHeading(heading));
  history.push(link)
}

  const {locale, navStyle, heading} = useSelector(({settings}) => settings);
  const { navCollapsed, width} = useSelector(({common}) => common);
  const {searchText, setSearchText} = useState('');
  
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  return (
    <Header className="finally-header-main">
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-2">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <div className='r-logo'>
          <img alt="" src={require("assets/images/w-logo.png")}/>
        </div>
      </Link>
      <span className='mainHeader'>{heading}</span> 
     
      <ul className="gx-header-notifications gx-ml-auto">
      {/* <li className="gx-settings">
        <Link to={"usersettings"} onClick={(e) => { e.preventDefault(); changeHeading("Settings", "usersettings") }}>
          <Tooltip placement="bottomLeft" title={<IntlMessages id="app.dict.settings" />}>
            <SettingOutlined className={'topBar-icon'} />
          </Tooltip>
        </Link>
      </li>
      <li className="gx-notify">
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
            trigger="click">
          <span className="gx-pointer gx-d-block">
                <BellOutlined className={'topBar-icon'} />
          </span>
        </Popover>
      </li> */}
              {/* <li className="gx-language">
                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                         content={languageMenu()} trigger="click">
              <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                className={`flag flag-24 flag-${locale.icon}`}/>
              </span>
                </Popover>
              </li> */}
        <li className="gx-user-nav"><UserInfo/></li>
      </ul>
    </Header>
  );
};

export default Topbar;
