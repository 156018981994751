import {FETCH_UPLOAD_LOANS, FETCH_UPLOAD_CUSTOMERS} from "constants/ActionTypes";

const INIT_STATE = {
  uploadedCustomers:[],
  uploadedLoans:[]
  };

  export default (state = INIT_STATE, action) => {

    switch (action.type) {
 
      case FETCH_UPLOAD_CUSTOMERS: {
        return {
          ...state,
          uploadedCustomers: action.payload,
        };
      }
      case FETCH_UPLOAD_LOANS: {
        return {
          ...state,
          uploadedLoans: action.payload,
        };
      }

     

      default:
        return state;
    }
  }