import React from "react";
import { Divider } from 'antd';
import {Link,useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setHeading} from "../../../appRedux/actions/Setting";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faHistory } from '@fortawesome/free-solid-svg-icons';

const timer=faHistory;

const MobileFooter=()=>{
    const history = useHistory();
  const dispatch = useDispatch();
  
  const changeHeading=(heading,link)=>{
    dispatch(setHeading(heading));
    history.push(link)
  }
   return ( 
        <div className='mobilefooter-wrapper' style={{ position: "sticky", bottom: "0" }}>
            <ul className="gx-app-nav mobilefooter">
                <li>
                <Link to="/mudashboard"  onClick={(e) => { e.preventDefault(); changeHeading("MyDashboard", "/mudashboard") }}>
                    <FontAwesomeIcon  icon={faHome} className={'mobilefooter-icon'} />
                </Link>
                </li>
                <Divider type='vertical'/>
                <li>
                <Link to="/muhistory" onClick={(e) => { e.preventDefault(); changeHeading("History", "/muhistory") }}>
                    <FontAwesomeIcon  icon={timer} className={'mobilefooter-icon'} />
                </Link>
                </li>
                <Divider type='vertical'/>
                <li>
                <Link to="/userprofile" onClick={(e) => { e.preventDefault(); changeHeading("Profile", "/userprofile") }}>
                    <FontAwesomeIcon  icon={faUser} className={'mobilefooter-icon'} />
                </Link>
                </li>
            </ul>
        </div>
    )
};

export default MobileFooter;