import {FETCH_RP_ALL_CUSTOMERS} from "../../constants/ActionTypes";
const INIT_STATE = {
   rpallCustomers:[],
   rpallQRCodes:[]
  };

  export default (state = INIT_STATE, action) => {

    switch (action.type) {
 
      case FETCH_RP_ALL_CUSTOMERS: {
        return {
          ...state,
          rpallCustomers: action.payload,
        };
      }

      default:
        return state;
    }
  }