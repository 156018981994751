import { Col, Row } from "antd";
import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const layoutcontainerclass = 'PA-Payment-container';

const App = ({ match }) => (
  
  <div className={layoutcontainerclass+" gx-main-content-wrapper"}>
    {/* <Row gutter={16}>
<Col span={18} > */}
    <Switch>
      {/* Admin */}
      
      
      {/* Organization */}
     
     
      {/* User */}



      {/* Public */}
      {/* <Route path={`${match.url}forgotpwd`} component={asyncComponent(() => import('./public/UserForgotPassword'))} />
      <Route path={`${match.url}signin`} component={asyncComponent(() => import('./public/Signin'))} />
      <Route path={`${match.url}signup`} component={asyncComponent(() => import('./public/Signup'))} />
     

      <Route path={`${match.url}upload`} component={asyncComponent(() => import('../components/UploadComponent'))} /> */}

      {/* PayAid */}
      <Route path={`${match.url}PaymentInformation`} component={asyncComponent(() => import('./public/PayAid/PayMentInfo'))} />
      <Route path={`${match.url}PaymentProcess`} component={asyncComponent(() => import('./public/PayAid/PaymentProcess'))} />
      <Route path={`${match.url}PaymentStatus`} component={asyncComponent(() => import('./public/PayAid/PaymentStatus'))} />
    

    </Switch>
    {/* </Col>
    </Row> */}
  </div>
);

export default App;
