import {FETCH_RP_ALL_QRCODES,FETCH_RP_GET_QRCODE_BY_LOANID,FETCH_RP_GET_QRCODE_BY_QRCODEID} from "constants/ActionTypes";
const INIT_STATE = {
   rpallQRCodes:[],
   rpQRCodeBYLoanID:{},
   rpQRCodeBYQRCodeID:{}
  };

  export default (state = INIT_STATE, action) => {

    switch (action.type) {
 
      case FETCH_RP_ALL_QRCODES: {
        return {
          ...state,
          rpallQRCodes: action.payload,
        };
      }

      case FETCH_RP_GET_QRCODE_BY_LOANID: {
        return {
          ...state,
          rpQRCodeBYLoanID: action.payload,
        };
      }

      case FETCH_RP_GET_QRCODE_BY_QRCODEID: {
        return {
          ...state,
          rpQRCodeBYQRCodeID: action.payload,
        };
      }
     

      default:
        return state;
    }
  }