import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { orgSignUp, userSignUp } from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { formatCountdown } from "antd/lib/statistic/utils";
import { checkEmail } from "../services/sharedServices";




const FormItem = Form.Item;

const SignUpComp = (props) => {
  const token = useSelector(({ auth }) => auth.token);
  const [form] = Form.useForm();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
  console.log("came here")
      let data = {
        Id: 0,
        Organization_Password: values.Organization_Password,
        Organization_Contact_Number: values.Organization_Contact_Number,
        Organization_Address: "",
        Organization_State: "",
        Organization_Country: "",
        Is_Active: true,
        Organization_Name: values.Organization_Name,
        Organization_First_Name: values.Organization_First_Name,
        Organization_Last_Name: values.Organization_Last_Name,
        Organization_Email: values.Organization_Email,
      };

      //dispatch(userSignUp(data));
      orgSignUp(data).then(data => {
        console.log("Org Registration Response:", data);
        if (data.status === 200 || 201) {
          //Created Employee
          //props.history.push("/thankyou");
          showNotification();


        }
        else {
          //check the error to display
          NotificationManager.error(<IntlMessages id="notification.orgsignup.errormessage" />, <IntlMessages
            id="notification.orgsignup.errortitle" />);

        }
      }).catch(err => {
        console.log("ERROR Org Rgstr:", err);
      })
      console.log('Received values of form: ', values);
    
  };

  const onConfigClose = () => {
    props.history.push("/signin");
  };

  const showNotification = () => {
    NotificationManager.success(
      <IntlMessages id="notification.orgsignup.message" />,
      <IntlMessages id="notification.orgsignup.title" />,
      onConfigClose()
    );
  };

 

  useEffect(() => {
    if (token !== null) {
      props.history.push("/");
    }
  });

  return (
    <>
      <div className="gx-app-login-wrap signupbox">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-wid">
                <h1 className="hiddenmobie">
                  <IntlMessages id="app.userAuth.signUp" />
                </h1>
                <p className="hiddenmobie">
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p className="hiddenmobie">
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form
                form={form}
                initialValues={{ remember: true }}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
                scrollToFirstError={true}
              >
                <h1>Organization Account</h1>
                <br />
                <FormItem
                  name="Organization_Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      validator(rule, value, callback, source, options) {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        let valid = re.test(String(value).toLowerCase());

                        if (valid) {
                          let data = { Email_To_Validate: value };
                          checkEmail(token, data)
                            .then((res) => {
                              callback();
                            })
                            .catch((err) => {
                              if (!err.response.data.data.is_Valid) {
                                callback(new Error("emial id already esists"));
                              } else {
                                callback(
                                  new Error("network error please try later")
                                );
                              }
                            });
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Organization Admin Email"/>
                </FormItem>
                <Form.Item
                  name="Organization_Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Organization name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Organization Name" />
                </Form.Item>
                <Form.Item
                  name="Organization_First_Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid First Name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Admin User's First Name" />
                </Form.Item>
                <Form.Item
                  name="Organization_Last_Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Last Name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Admin User's Last Name" />
                </Form.Item>
                <Form.Item
                  name="Organization_Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Password!",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <FormItem>
                  <Form.Item
                    name="Organization_Contact_Number"
                    rules={[
                      {
                        required: false,
                        message: "Please input a valid Contact Number!",
                      },
                    ]}
                  >
                    <Input
                      // addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                      placeholder="Contact"
                    />
                  </Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                </FormItem>
                <Form.Item>
                  <span>
                    <IntlMessages id="app.userAuth.singInMess" />
                  </span>{" "}
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </Form.Item>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpComp;
