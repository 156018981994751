import React from "react";
import {useDispatch} from "react-redux";
import { Popover, Divider, Tooltip} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {EllipsisOutlined, UserOutlined, FieldTimeOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import {SIGNOUT_USER_SUCCESS} from "constants/ActionTypes";
import IntlMessages from "../../util/IntlMessages";
import { setHeading } from "../../appRedux/actions/Setting";

const UserInfo = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  
  function signOut(){
    localStorage.clear();
    history.push('/');
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  }

  const changeHeading = (heading, link) => {
    dispatch(setHeading(heading));
    history.push(link)
  }

  const userMenuOptions = (
    <ul className="userProfile-menu gx-user-popover">
      <li><Link to={"userprofile"} onClick={(e) => { e.preventDefault(); changeHeading("Profile", "/userprofile") }}><UserOutlined className={'userProfile-menu-icon'}/>
                <span><IntlMessages id="app.dict.profile" /></span>
              </Link></li>
      <li><Link to={"activitylog"} onClick={(e) => { e.preventDefault(); changeHeading("Activity Log", "/activitylog") }}><FieldTimeOutlined className={'userProfile-menu-icon'}/>
                <span><IntlMessages id="app.dict.activityLog" /></span>
              </Link></li>
      <Divider />
      <li onClick={() => signOut()}>
      <LogoutOutlined  className={'userProfile-menu-icon'}/><span><IntlMessages id="app.userAuth.logout" /></span>
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
               {/* <Tooltip placement="bottomLeft" title={<IntlMessages id="app.dict.myAccout" />}> */}
                  
               <EllipsisOutlined className={'topBar-icon userProfile-icon'}/>
               {/* </Tooltip> */}
    </Popover>
  )

}

export default UserInfo;
