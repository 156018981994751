import React from "react";
import {Link,useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setHeading} from "../../appRedux/actions/Setting";



const AppsNavigation = () =>{
  const history = useHistory();
  const dispatch = useDispatch();
  
  const changeHeading=(heading,link)=>{
    dispatch(setHeading(heading));
    history.push(link)
  }
  return(<></>
  // <ul className="gx-app-nav appProfielBtn">
  //   <li>
  //     <Link to="/settings" onClick={(e)=>{e.preventDefault();changeHeading("Settings","/settings")}}>
  //       <i className="icon icon-setting"/>
  //     </Link>
  //   </li>
  //   <li>
  //     <Link to="/profile" onClick={(e)=>{e.preventDefault();changeHeading("Profile","/profile")}}>
  //       <i className="icon icon-profile2"/>
  //     </Link>
  //   </li>
  // </ul>
  )
  };

export default AppsNavigation;
