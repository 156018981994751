import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_FINALLYID_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api';
import { userLogin } from '../../services/sharedServices'
import { getMyIPaddress } from '../../services/External'
import config from '../../config/index';
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const orgSignUp = (data) => {
  let orgSignUpApi = config.commonApi.orgSignUpApi;
  return axios.post(orgSignUpApi, data, {
    headers: {},
  });
};

export const userSignUp = ({ email, password, name }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/register', {
      email: email,
      password: password,
      name: name
    }
    ).then(({ data }) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        console.log("payload: data.error", data.error);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
var ipData="0.0.0.0";
    // getMyIPaddress().then((ipData) => {

    userLogin({
      userName: email,
      password: password,
      ipAddress:ipData,//.data.IPv4||"0.0.0.0",
      refreshToken_exipiry_days:2
    }).then(({ data }) => {

      if (data.token) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("role", data.role);
        localStorage.setItem("user", `${JSON.stringify(data.userName)}`)
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data });
        dispatch({ type: USER_TOKEN_SET, payload: data.token });
        dispatch({ type: USER_FINALLYID_SET, payload: data.finAllyId });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: "User Deatils Invalid" });
      console.log("Error****:", error.message);
    });
  // });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/me',
    ).then(({ data }) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/logout').then(({ data }) => {
      console.log("log out", data)
      if (data.result) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};
