import {FETCH_ALL_EMPLOYEES, FETCH_ORGANIZATION_PROFILE} from "../../constants/ActionTypes";
const INIT_STATE = {
   allEmployeeDetails:[],
   organizationProfile:{}
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
 
      case FETCH_ALL_EMPLOYEES: {
        return {
          ...state,
          allEmployeeDetails: action.payload,
        };
      }
      case FETCH_ORGANIZATION_PROFILE: {
        return {
          ...state,
          organizationProfile: action.payload,
        };
      }

     

      default:
        return state;
    }
  }