import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Avatar } from "antd";
import { setHeading } from "../../appRedux/actions/Setting";


const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { fullName } = authUser

  const changeHeading = (heading, link) => {
    dispatch(setHeading(heading));
    history.push(link)
  }


  return (
    <div className="gx-flex-row gx-align-items-center  gx-avatar-row">
      <Link to="/orgprofile" onClick={(e) => { e.preventDefault(); changeHeading("Profile", "/orgprofile") }}>
        <Avatar src={"images/org.png"}
          className="myavatar gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">{fullName}</span>
      </Link>
    </div>
  )
};



export default UserProfile;
