import {ASSESMENT_HISTORY,USER_PROFILE} from "../../constants/ActionTypes";
const INIT_STATE = {
    employeeAssessmentHistoryData:{},
    userProfileData:{}
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
 
      case ASSESMENT_HISTORY: {
        return {
          ...state,
          employeeAssessmentHistoryData: action.payload,
        };
      }

      case USER_PROFILE: {
        return {
          ...state,
          userProfileData: action.payload,
        };
      }

      

      default:
        return state;
    }
  }