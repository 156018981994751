import {FETCH_QUESTIONS,ASSESMENT_RESULT,ASSESMENT_STATUS, ASSESMENT_FLAG} from "../../constants/ActionTypes";
const INIT_STATE = {
    questionsData:[],
    assesmentResult:'',
    assesmentTaken:false,
    assesmentFlag:'',
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
 
      case FETCH_QUESTIONS: {
        return {
          ...state,
          questionsData: action.payload,
        };
      }

      case ASSESMENT_RESULT: {
        return {
          ...state,
          assesmentResult: action.payload,
        }

      }
      case ASSESMENT_FLAG: {
        return {
          ...state,
          assesmentFlag : action.payload
        }

      }
      case ASSESMENT_STATUS:{
        return {
          ...state,
          assesmentTaken: action.payload
        }
      }

      default:
        return state;
    }
  }